exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-audit-tsx": () => import("./../../../src/pages/audit.tsx" /* webpackChunkName: "component---src-pages-audit-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-speaking-tsx": () => import("./../../../src/pages/speaking.tsx" /* webpackChunkName: "component---src-pages-speaking-tsx" */),
  "component---src-pages-training-tsx": () => import("./../../../src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */)
}

